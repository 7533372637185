@use "sass:math"
@import "app/styles/mixins"
@import "app/styles/bootstrap/variables"

$mapHeight: 1536
$mapWidth: 2350
$campaignPortalWidth: 317px
$campaignPortalHeight: 634px
$sideCampaignImagesWidth: 1902px
$campaignHoverScale: 1.2
$betaImagesWidth: 951px
$levelDotWidth: 2%
$levelDotHeight: math.div($levelDotWidth * $mapWidth, $mapHeight)
$levelDotZ: $levelDotHeight * 0.25
$levelDotHoverZ: $levelDotZ * 1.5
$levelDotShadowWidth: 0.8 * $levelDotWidth
$levelDotShadowHeight: 0.8 * $levelDotHeight
$levelClickRadius: 20px
$gameControlSize: 80px
$gameControlMargin: 30px

+keyframes(levelStartedPulse)
  from
    @include box-shadow(0px 0px 4px #333)
    margin-bottom: math.div(-$levelDotHeight, 3) + $levelDotZ
  50%
    @include box-shadow(0px 0px 22px skyblue)
    margin-bottom: math.div(-$levelDotHeight, 3) + math.div(($levelDotHoverZ + $levelDotZ), 2)
  to
    @include box-shadow(0px 0px 4px #333)
    margin-bottom: math.div(-$levelDotHeight, 3) + $levelDotZ

#campaign-view
  top: 0
  right: 0
  bottom: 0
  left: 0
  position: absolute

  .gradient
    position: absolute
    z-index: 0

    &.horizontal-gradient
      left: 0
      right: 0
      height: 3%

    &.vertical-gradient
      top: 0
      bottom: 0
      width: 3%

    &.top-gradient
      top: 0

    &.right-gradient
      right: 0

    &.bottom-gradient
      bottom: 0

    &.left-gradient
      left: 0

  .map
    position: relative

    #brain-pop-done-banner-wrapper
      position: absolute
      top: 20%
      width: 100%
      z-index: 3
      #brain-pop-done-banner
        background: url("/images/pages/base/modal_background.png")
        background-size: 100% 100%
        width: 400px
        margin: 0 auto
        padding: 20px 40px
        text-align: center
        img
          margin-bottom: 10px

    // Roblox modal
    #roblox-level-container
      position: absolute
      left: 18%
      top: 26%
      background-color: rgb(80, 130, 200)
      .roblox-icon
        background: url('/images/roblox/roblox-icon.png')
        width: 410%
        aspect-ratio: 20 / 19
        background-size: contain
        position: relative
        background-repeat: no-repeat
        transform: translate(-50%, -50%)
        left: 50%
        top: -80%

    // HackStack modal
    #hackstack-level-container
      position: absolute
      left: 61%
      top: 66%
      background-color: rgb(80, 130, 200)
      a
        display: inline
      .hackstack-icon
        background: url('/images/pages/hackstack/hackstack-logo-square-transparent-256.png')
        width: 200%
        aspect-ratio: 1
        background-size: contain
        position: relative
        background-repeat: no-repeat
        transform: translate(-50%, -50%) scaleY(calc(4/3))
        left: 50%
        top: -80%

    .map-background
      width: 100%
      height: 100%
      background-size: 100%
      @include user-select(none)

    .level, .level-shadow
      position: absolute
      border-radius: 50%
      -webkit-transform: scaleY(0.75)
      transform: scaleY(0.75)

    .level
      z-index: 2
      width: $levelDotWidth
      height: $levelDotHeight
      margin-left: -0.5 * $levelDotWidth
      margin-bottom: math.div(-$levelDotHeight, 3) + $levelDotZ
      border: 2px groove white
      @include transition(margin-bottom 0.5s ease)
      cursor: pointer

      .treasure-chest
        position: absolute
        left: 50%
        width: 150%
        transform: translateX(-50%) translateX(50%) translateY(-130%) scaleY(1.33) scale(0.75)
        cursor: pointer
        z-index: -1
        filter: drop-shadow(0px 0px 4px gold)

      &.disabled, &.locked
        //background-image: url(/images/pages/game-menu/lock.png)
        //background-size: 75%
        //background-repeat: no-repeat
        //background-position: 50% 50%
        opacity: 0.7
        width: $levelDotWidth * 0.67
        height: $levelDotHeight * 0.67
        margin-left: -0.5 * 0.67 * $levelDotWidth
        margin-bottom: math.div(math.div(-$levelDotHeight, 3) * 0.67 + $levelDotZ, 2)

        .treasure-chest
          transform: translateX(-50%) translateY(-200%) scaleY(1.33) scale(0.95)


      &.started, &.next
        border: 3px solid lightgreen
        @include box-shadow(0px 0px 35px skyblue)

        // Would be cool, but kills performance, since we have to re-render all the time.
        //&:not(:hover)
        //  -webkit-animation-name: levelStartedPulse
        //  -webkit-animation-duration: 3s
        //  -webkit-animation-iteration-count: infinite

      &.complete
        border: 3px solid gold
        @include box-shadow(0px 0px 35px skyblue)

      .level-difficulty-banner-text
        position: absolute
        bottom: 160%
        pointer-events: none
        color: rgb(246, 208, 2)
        text-shadow: 0px 1px 0px black
        font-size: 1.8vw
        z-index: 1
        width: 100%
        text-align: center

      img.banner
        position: absolute
        bottom: 38%
        width: 170%
        left: -35%

      img.level-star
        width: 100%
        bottom: 4%
        position: absolute
        pointer-events: none

      .level-acronym, .level-kind
        position: absolute
        pointer-events: none
        left: 0
        top: 0
        right: 0
        bottom: 0
        color: black
        text-align: center
        font-size: 0.8vw
        text-shadow: 0px 1px 0px white

      .level-kind
        bottom: auto
        top: 100%
        color: white
        text-shadow: 0px 1px 0px black, 0px -1px 0px black, -1px 0px 0px black, 1px 0px 0px black

        &.demo
          color: rgb(128, 255, 128)
        &.usage
          color: rgb(128, 128, 255)
        &.mastery
          color: rgb(255, 128, 128)
        &.practice
          color: rgb(128, 128, 255)
        &.advanced
          color: rgb(128, 255, 255)
        &.challenge
          color: rgb(255, 255, 128)

      img.hero-portrait, img.item-portrait
        width: 120%
        height: auto
        bottom: 75%
        left: 75%
        margin-left: 0
        margin-bottom: 0

    img.premium-hero-banner
      position: absolute
      height: 250%
      transform: translate(-50%, -100%) translateX(-20%) translateY(-10%) scaleY(1.33)
      filter: drop-shadow(0px 0px 10px black)
      z-index: 3

    img.pet-banner
      position: absolute
      height: 250%
      transform: translate(-50%, -80%) translateX(-20%) translateY(-10%) scaleY(1.33) scale(0.625)
      filter: drop-shadow(0px 0px 10px black)
      z-index: 3

    img.hero-portrait, img.item-portrait
      position: absolute
      border: 1px solid black
      border-radius: 50%
      background: white
      width: $levelDotWidth * 1.5
      height: math.div($levelDotHeight * 1.5, 1.75)
      margin-left: -0.5 * $levelDotWidth * 1.5
      margin-bottom: math.div(math.div(-$levelDotHeight, 3) * 1.5, 1.75)

      &.hero-portrait-on-banner, &.item-portrait-on-banner
        bottom: 170%
        left: 0%
        width: 100%

      &.item-portrait-on-banner
        background: transparent
        border: 0
        border-radius: 0

    .level-shadow
      z-index: 1
      width: $levelDotShadowWidth
      height: $levelDotShadowHeight
      margin-left: -0.5 * $levelDotShadowWidth
      margin-bottom: math.div(-$levelDotShadowHeight, 3)
      background-color: black
      @include box-shadow(0px 0px 10px black)
      @include opacity(0.75)

      &.locked
        @include opacity(0.5)
        width: $levelDotShadowWidth * 0.67
        height: $levelDotShadowHeight * 0.67
        margin-left: -0.5 * 0.67 * $levelDotShadowWidth
        margin-bottom: math.div(-$levelDotShadowHeight, 3) * 0.67

    .level:hover
      // TODO: This rotate stops Firefox from flickering, but also disables the scaleY(0.75)
      // TODO: The dot looks like it's jumping.
      // TODO: -moz-transform: scaleY(0.75) didn't do anything
      // TODO: Does not break Chrome's oval.
      -moz-transform: rotate(0)
      margin-bottom: math.div(-$levelDotHeight, 3) + $levelDotHoverZ
      @include box-shadow(0px 0px 35px skyblue)

    .level
      a
        display: block
        padding: $levelClickRadius
        margin-left: -0.5 * $levelClickRadius
        margin-top: -0.5 * $levelClickRadius
        border-radius: $levelClickRadius

    .tooltip
      z-index: 3
      pointer-events: none

      body[lang="he"] &, body[lang="ar"] &, body[lang="fa"] &, body[lang="ur"] &
        direction: rtl

      .tooltip-arrow
        display: none

    .level-info-container
      display: none
      position: absolute
      z-index: 3
      width: 362px
      //min-height: 179px
      padding: 17px 20px 20px 20px
      border: 0
      background: transparent url(/images/pages/play/level-info-background.png) no-repeat center center
      background-size: 100% 100%

      &.has-loading-error
        .level-error-message
          display: block
          margin: 20px
          font-size: 16px
        .level-info > *:not(.level-error-message, h3, .level-status)
          display: none

      .level-info

        h3
          color: rgb(232, 217, 87)
          text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0
          padding: 0 2px
          margin: 0 0 10px 31px
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis

        .level-description
          color: black

          img
            display: block
            margin: 0px auto
            max-width: 100%

        kbd
          margin: 0 2px 2px 0
          display: inline-block
          font-size: 12px

        .level-status
          background: transparent url(/images/pages/play/level-info-status-spritesheet.png) no-repeat 0 0
          width: 60px
          height: 60px
          position: absolute
          left: -15px
          top: -15px

        &.complete .level-status
          background-position: -60px 0

        &.premium .level-status
          background-position: -120px 0

        &.complete.premium .level-status
          background-position: -180px 0

        .start-level, .start-esports
          min-width: 200px
          display: block
          margin: 10px auto 0 auto
          position: relative

          .badge
            position: absolute
            top: auto
            left: auto
            right: -25px
            bottom: -25px
            font-size: 20px
            color: black
            border: 1px solid black
            background-color: rgb(232, 217, 87)
            border-radius: 50%
            opacity: 1
            padding: 3px 9px

        &.shows-leaderboard
          .start-level, .view-solutions
            min-width: calc(50% - 5px)
            display: inline-block
            width: calc(50% - 5px)

          .start-level
            margin: 10px 0 0 5px

          .view-solutions
            margin: 10px 5px 0 0

        .course-version, .home-version
          text-align: center

        .threshold-icon
          height: 40px
          filter: drop-shadow(2px 2px 0px #222)
          position: absolute
          right: -13px
          top: -17px

        .practice-license-message
          font-style: italic

      &.has-practice-levels
        .course-version, .home-version
          float: right

          .btn
            height: 33px

          .btn-success
            @include animation(winnablePulse 3s infinite)

            span
              opacity: 1

      .practice-level
        h3
          margin-left: 0

    .campaign-switch
      color: purple
      position: absolute
      z-index: 1
      font-size: 2vw
      text-shadow: 0 0 0.3vw white, 0 0 0.3vw white

      &:hover
        text-decoration: none

  .next-level-line
    height: 8px
    position: absolute

    .line
      width: calc(100% - 24px)
      float: left
      margin-top: 2px
      margin-bottom: 2px
      height: 4px
      background: repeating-linear-gradient(-45deg, #AF9F7D, #DFC89C 5px, #F1EAC0 5px, #AF9F7D 10px)
      box-shadow: 0px 0px 4px black

    .point
      width: 24px
      height: 24px
      float: right
      margin-top: -8px
      border-top: 12px solid transparent
      border-bottom: 12px solid transparent
      border-left: 24px solid lighten(#F1EAC0, 10%)

  .game-controls
    position: absolute
    right: 1%
    bottom: 1%
    z-index: 1

    .btn
      &:not(:first-child)
        margin-left: $gameControlMargin
        @media only screen and (max-height: 650px)
          margin-left: 0

      width: $gameControlSize
      height: $gameControlSize

      @media only screen and (max-height: 650px)
        @include scale(0.67)

      background: url(/images/pages/play/menu_icons.png) no-repeat

      position: relative
      img
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%

      background-size: cover
      @include transition(0.5s ease)
      @include box-shadow(2px 2px 4px black)
      border: 0
      border-radius: 12px
      // IE9 shows a blank white button with this MS gradient filter in place
      filter: none

      &:hover
        @include box-shadow(0 0 12px #bbf)

      &:active, &.highlighted
        @include box-shadow(0 0 20px white)

      &.items
        background-position: (-1 * $gameControlSize) 0px
      &.heroes
        background-position: (-2 * $gameControlSize) 0px
      &.achievements
        background-position: (-3 * $gameControlSize) 0px
      &.account
        background-position: (-5 * $gameControlSize) 0px
      &.settings
        background-position: (-5 * $gameControlSize) 0px
      &.gems
        background-position: (-6 * $gameControlSize) 0px
      &.poll
        background-position: (-4 * $gameControlSize) 0px
      &.premium-menu-icon
        background: url(/images/pages/play/premium-menu-icon.png) no-repeat
        background-position: 50% 50%
        background-size: 100%
        transform: scale(1.31) translateY(-5px) translateX(-6px)
        @media only screen and (max-height: 650px)
          transform: scale(0.67) scale(1.31) translateY(-5px) translateX(-6px)
        box-shadow: initial
        filter: drop-shadow(2px 2px 4px black)
        outline: none
        &:hover
          filter: drop-shadow(0 0 2px #bbf)
      &.premium-menu-icon
        background: url(/images/pages/play/premium-menu-icon.png) no-repeat
        background-position: 50% 50%
        background-size: 100%
        transform: scale(1.31) translateY(-5px) translateX(-6px)
        @media only screen and (max-height: 650px)
          transform: scale(0.67) scale(1.31) translateY(-5px) translateX(-6px)
        box-shadow: initial
        filter: drop-shadow(2px 2px 4px black)
        outline: none
        &:hover
          filter: drop-shadow(0 0 2px #bbf)
      &.roblox-menu-icon, &.hackstack-menu-icon
        background: url(/images/roblox/roblox-menu-icon.png) no-repeat
        background-position: 50% 50%
        background-size: 100%
        transform: scale(1.2) translateY(-3px) translateX(-10px)
        @media only screen and (max-height: 650px)
          transform: scale(0.78) translateY(-1px)
        box-shadow: initial
        filter: drop-shadow(2px 2px 4px black)
        outline: none
        &:hover
          filter: drop-shadow(0 0 2px #bbf)

      &.junior-menu-icon
        background: url(/images/pages/play/campaign/coco-junior-square-menu-icon.webp) no-repeat
        background-position: 50% 50%
        background-size: 110%
        transform: scale(1.2) translateX(-10px)
        @media only screen and (max-height: 650px)
          transform: scale(0.78) translateY(-1px)
        box-shadow: initial
        filter: drop-shadow(2px 2px 4px black)
        outline: none
        &:hover
          filter: drop-shadow(0 0 2px #bbf)

        // Jiggle animation
        +keyframes(jiggle)
          0%
            transform: scale(1.1) translateY(-1px) rotate(0deg)
          25%
            transform: scale(1.1) translateY(-1px) rotate(1deg)
          50%
            transform: scale(1.1) translateY(-1px) rotate(0deg)
          75%
            transform: scale(1.1) translateY(-1px) rotate(-1deg)
          100%
            transform: scale(1.1) translateY(-1px) rotate(0deg)

        &.highlighted
          @include animation(jiggle .3s 20)

      &.hackstack-menu-icon
        background: url('/images/pages/hackstack/hackstack-logo-square-transparent-256.png') no-repeat
        background-position: 50% 50%
        background-size: 100%
        border-radius: 0
        transform: scale(1) translateX(-20px) translateY(-3px)

    .tooltip
      font-size: 24px

      .tooltip-arrow
        display: none
  
  .game-controls-catalyst
    position: absolute
    left: 2%
    top: 50%
    transform: translateY(-50%)
    z-index: 1
    display: flex
    flex-direction: column
    gap: 10px

    
    .btn
      &:not(:first-child)
        margin-left: 0
        margin-top: $gameControlMargin
        @media only screen and (max-height: 650px)
          margin-top: 10px

      width: $gameControlSize
      height: $gameControlSize

      @media only screen and (max-height: 650px)
        @include scale(0.67)

      background: url(/images/pages/play/menu_icons.png) no-repeat

      position: relative
      img
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%

      background-size: cover
      @include transition(0.5s ease)
      @include box-shadow(2px 2px 4px black)
      border: 0
      border-radius: 12px
      // IE9 shows a blank white button with this MS gradient filter in place
      filter: none

      &:hover
        @include box-shadow(0 0 12px #bbf)

      &:active, &.highlighted
        @include box-shadow(0 0 20px white)

      &.items
        background-position: (-1 * $gameControlSize) 0px
      &.heroes
        background-position: (-2 * $gameControlSize) 0px
      &.achievements
        background-position: (-3 * $gameControlSize) 0px
      &.account
        background-position: (-5 * $gameControlSize) 0px
      &.settings
        background-position: (-5 * $gameControlSize) 0px
      &.gems
        background-position: (-6 * $gameControlSize) 0px
      &.poll
        background-position: (-4 * $gameControlSize) 0px
      &.premium-menu-icon
        background: url(/images/pages/play/premium-menu-icon.png) no-repeat
        background-position: 50% 50%
        background-size: 100%
        transform: scale(1.31) translateY(-5px) translateX(-6px)
        @media only screen and (max-height: 650px)
          transform: scale(0.67) scale(1.31) translateY(-5px) translateX(-6px)
        box-shadow: initial
        filter: drop-shadow(2px 2px 4px black)
        outline: none
        &:hover
          filter: drop-shadow(0 0 2px #bbf)
      &.premium-menu-icon
        background: url(/images/pages/play/premium-menu-icon.png) no-repeat
        background-position: 50% 50%
        background-size: 100%
        transform: scale(1.31) translateY(-5px) translateX(-6px)
        @media only screen and (max-height: 650px)
          transform: scale(0.67) scale(1.31) translateY(-5px) translateX(-6px)
        box-shadow: initial
        filter: drop-shadow(2px 2px 4px black)
        outline: none
        &:hover
          filter: drop-shadow(0 0 2px #bbf)

        // Jiggle animation
        +keyframes(jiggle)
          0%
            transform: scale(1.1) translateY(-1px) rotate(0deg)
          25%
            transform: scale(1.1) translateY(-1px) rotate(1deg)
          50%
            transform: scale(1.1) translateY(-1px) rotate(0deg)
          75%
            transform: scale(1.1) translateY(-1px) rotate(-1deg)
          100%
            transform: scale(1.1) translateY(-1px) rotate(0deg)

        &.highlighted
          @include animation(jiggle .3s 20)
      
    &.in-campaign
      flex-direction: row
      bottom: 2%
      top: auto
      transform: translateY(0)
      .btn
        margin-top: 0
        &:not(:first-child)
          margin-left: 10px

    .tooltip
      font-size: 24px

      .tooltip-arrow
        display: none

  .other-products-catalyst
    position: absolute
    right: 2%
    top: 50%
    transform: translateY(-50%)
    z-index: 1
    display: flex
    flex-direction: column
    gap: 10px

    .btn
      &:not(:first-child)
        margin-left: 0
        margin-top: $gameControlMargin
        @media only screen and (max-height: 650px)
          margin-top: 10px

      width: $gameControlSize
      height: $gameControlSize

      @media only screen and (max-height: 650px)
        @include scale(0.67)

      background: url(/images/pages/play/menu_icons.png) no-repeat

      position: relative
      img
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%

      background-size: cover
      @include transition(0.5s ease)
      @include box-shadow(2px 2px 4px black)
      border: 0
      border-radius: 12px
      // IE9 shows a blank white button with this MS gradient filter in place
      filter: none

      &:hover
        @include box-shadow(0 0 12px #bbf)

      &:active, &.highlighted
        @include box-shadow(0 0 20px white)

      &.roblox-menu-icon
        background: url('/images/pages/roblox/Code-Combat-Worlds-Small.png') no-repeat
        background-position: 50% 50%
        background-size: 100%
        margin-top: 40px
        transform: scale(1.4) translateY(-3px) translateX(-15px)
        @media only screen and (max-height: 650px)
          transform: scale(0.78) translateY(-1px)
        box-shadow: initial
        filter: drop-shadow(2px 2px 4px black)
        outline: none
        &:hover
          filter: drop-shadow(0 0 2px #bbf)

      &.hackstack-menu-icon
        background: url('/images/pages/hackstack/Code-Combat-AI-Hack-Stack-Full-Colour-Small.png') no-repeat
        background-position: 50% 50%
        background-size: 100%
        border-radius: 0
        transform: scale(2) translateX(-17px)
        @media only screen and (max-height: 650px)
          transform: scale(0.78) translateY(-1px)
        box-shadow: initial
        filter: drop-shadow(2px 2px 4px black)
        outline: none
        &:hover
          filter: drop-shadow(0 0 2px #bbf)

      &.ai-league-menu-icon
        background: url('/images/pages/league/Code-Combat-AI-League-Small.png') no-repeat
        background-position: 50% 50%
        background-size: 90%
        margin-top: 40px
        transform: scale(1.4) translateY(-3px) translateX(-15px)
        @media only screen and (max-height: 650px)
          transform: scale(0.78) translateY(-1px)
        box-shadow: initial
        filter: drop-shadow(2px 2px 4px black)
        outline: none
        &:hover
          filter: drop-shadow(0 0 2px #bbf)
      
      &.junior-menu-icon
        background: url(/images/pages/play/campaign/coco-junior-square-menu-icon.webp) no-repeat
        background-position: 50% 50%
        background-size: 110%
        transform: scale(1.2) translateX(-10px)
        @media only screen and (max-height: 650px)
          transform: scale(0.78) translateY(-1px)
        box-shadow: initial
        filter: drop-shadow(2px 2px 4px black)
        outline: none
        &:hover
          filter: drop-shadow(0 0 2px #bbf)
      
        // Jiggle animation
        +keyframes(jiggle)
          0%
            transform: scale(1.1) translateY(-1px) rotate(0deg)
          25%
            transform: scale(1.1) translateY(-1px) rotate(1deg)
          50%
            transform: scale(1.1) translateY(-1px) rotate(0deg)
          75%
            transform: scale(1.1) translateY(-1px) rotate(-1deg)
          100%
            transform: scale(1.1) translateY(-1px) rotate(0deg)

        &.highlighted
          @include animation(jiggle .3s 20)
          

    &.in-campaign
      flex-direction: row
      bottom: 2%
      right: 50%
      top: auto
      transform: translateY(0) translateX(100%)

      .btn
        margin-top: 0
        height: 40px
        width: 40px
        &:not(:first-child)
          margin-left: 20px

        &.hackstack-menu-icon
          transform: scale(2) translateY(-5px)
          margin-right: 20px

        &.roblox-menu-icon
          transform: scale(2) translateY(-5px) translateX(10px)

        &.ai-league-menu-icon
          transform: scale(1.5) translateY(-5px)
        
    .tooltip
      font-size: 24px

      .tooltip-arrow
        display: none

  .user-status
    position: absolute
    bottom: 16px
    left: 8px
    text-align: center
    font-size: 24px
    color: white
    text-shadow: 0px 2px 1px black, 0px -2px 1px black, -2px 0px 1px black, 2px 0px 1px black
    height: 33px
    line-height: 33px

    .user-status-line
      position: relative

      .btn.btn-illustrated
        margin-left: 10px
        min-width: 90px
        height: 33px
        color: white

        option
          color: black

      .rtl-allowed
        display: inline-block

      .gem, .player-hero-icon
        position: absolute
        top: 1px

      #gems-count
        margin-left: 40px

      .player-level
        margin-left: 5px

      .player-name
        margin-left: 45px

      a
        color: white

      $spriteSheetSize: 30px

      .player-hero-icon
        background: transparent url(/images/pages/play/play-spritesheet.png)
        background-size: cover
        background-position: (-2 * $spriteSheetSize) 0
        display: inline-block
        width: 30px
        height: 30px
        margin: 0px 2px

      .level-indicator
        margin-left: 15px
        color: white
        display: inline-block
        margin: 0 2px

      .player-hero-icon
        margin-left: 10px
        background-position: (-4 * $spriteSheetSize) 0

        &.knight
          background-position: (-5 * $spriteSheetSize) 0
        &.librarian
          background-position: (-6 * $spriteSheetSize) 0
        &.ninja
          background-position: (-7 * $spriteSheetSize) 0
        &.potion-master
          background-position: (-8 * $spriteSheetSize) 0
        &.samurai
          background-position: (-9 * $spriteSheetSize) 0
        &.trapper
          background-position: (-10 * $spriteSheetSize) 0
        &.forest-archer
          background-position: (-11 * $spriteSheetSize) 0
        &.sorcerer
          background-position: (-12 * $spriteSheetSize) 0

  .user-status-catalyst
    position: absolute
    top: 10px
    right: 30px
    text-align: right
    font-size: 26px
    color: white
    text-shadow: 0px 2px 1px black, 0px -2px 1px black, -2px 0px 1px black, 2px 0px 1px black
    height: 40px
    line-height: 40px

    .stats-row
      margin-bottom: 10px
      margin-top: 10px
    
    .user-status-line
      position: relative
      display: flex
      align-items: center
      justify-content: flex-end

      .btn.btn-illustrated
        margin-left: 10px
        min-width: 90px
        height: 40px
        color: white

        option
          color: black

      .rtl-allowed
        display: inline-block
        margin-right: 10px

      .gem, .player-hero-icon
        position: relative
        top: auto

      #gems-count
        margin-left: 5px
        display: inline-flex
        align-items: center

      .player-level
        margin-left: 5px
        display: inline-flex
        align-items: center

      .player-name
        margin-left: 5px
        margin-right: 10px
        display: inline-flex
        align-items: center

      a
        color: white
      
      body[lang^="en"] #campaign-view .user-status .user-status-line .language-dropdown
        width: 90px

      $spriteSheetSize: 30px

      .player-hero-icon
        background: transparent url(/images/pages/play/play-spritesheet.png)
        background-size: cover
        background-position: (-2 * $spriteSheetSize) 0
        display: inline-block
        width: 30px
        height: 30px
        margin: 0px 4px
        transform: scale(1.33)  // Scale up by 1.33 to get from 30px to ~40px
        transform-origin: center center
        margin-left: 10px
        background-position: (-4 * $spriteSheetSize) 0

        &.knight
          background-position: (-5 * $spriteSheetSize) 0
        &.librarian
          background-position: (-6 * $spriteSheetSize) 0
        &.ninja
          background-position: (-7 * $spriteSheetSize) 0
        &.potion-master
          background-position: (-8 * $spriteSheetSize) 0
        &.samurai
          background-position: (-9 * $spriteSheetSize) 0
        &.trapper
          background-position: (-10 * $spriteSheetSize) 0
        &.forest-archer
          background-position: (-11 * $spriteSheetSize) 0
        &.sorcerer
          background-position: (-12 * $spriteSheetSize) 0

      .level-indicator
        margin-left: 15px
        color: white
        display: inline-block
        margin: 0 2px


  .campaign-control-button
    position: absolute
    right: 1%
    top: 1%
    padding: 3px 8px
    @include opacity(0.75)

    &:hover
      @include opacity(1.0)

    .glyphicon
      font-size: 32px
  
  .campaign-control-button-catalyst
    padding: 3px 8px
    @include opacity(0.75)

    &:hover
      @include opacity(1.0)

    .glyphicon
      font-size: 28px
    
    &.language-dropdown
      font-size: 24px

  #volume-button
    .glyphicon
      display: none

    &.vol-up .glyphicon.glyphicon-volume-up
      display: inline-block

    &.vol-off .glyphicon.glyphicon-volume-off
      display: inline-block
      @include opacity(0.50)
      &:hover
        @include opacity(0.75)

    &.vol-down .glyphicon.glyphicon-volume-down
      display: inline-block

  #back-button, #clear-storage-button
    position: absolute
    right: 70px
    right: -webkit-calc(1% + 55px)
    right: calc(1% + 55px)

  #campaign-status
    position: absolute
    left: 0
    top: 0
    width: 100%
    margin: 0
    text-align: center
    color: rgb(232, 217, 87)
    font-size: 28px
    text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0
    z-index: 30
    pointer-events: none

    .campaign-status-background
      background: transparent url(/images/pages/play/campaign-banner.png) no-repeat center center
      border-radius: 10px
      padding-top: 30px
      display: inline-block
      min-width: 250px
      height: 106px

      .campaign-name
        line-height: 26px

      .levels-completed
        font-size: 22px

  #anonymous-classroom-signup-dialog
    border-width: 0px
    padding: 0
    padding-bottom: 0
    width: 230px
    position: absolute
    right: 1%
    top: 5%
    top: -webkit-calc(1% + 50px)
    top: calc(1% + 50px)
    z-index: 30
    margin: 0
    text-align: center
    .modal-header
      padding: 8px
      background-color: #0E4C60
      h4, span
        color: white
        font-size: 13px
        line-height: 16px
        font-weight: bold
    .modal-body
      padding: 10px
      font-size: 13px
      line-height: 16px
      .strong
        font-weight: bold
      input
        width: 50%
        margin: 4px 4px 13px 0px
      #anon-classroom-signup-btn
        margin-top: 8px
      #anon-classroom-join-btn
        margin-top: -4px

    @media screen and (max-width: 800px)
      display: none

  .portal
    position: relative
    width: 100%
    height: 100%
    background: transparent url(/images/pages/play/portal-background.png)
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    @media (hover: none)
      overflow: scroll
      @media (orientation: landscape)
        align-items: initial

    .portals 
      $height: $campaignPortalHeight * $campaignHoverScale
      flex-wrap: nowrap
      display: flex
      overflow: hidden
      @media (hover: none)
        overflow: scroll

      .campaign
        width: $campaignPortalWidth
        height: $campaignPortalHeight
        margin-top: $campaignPortalHeight * math.div($campaignHoverScale - 1, 2)
        background: transparent url(/images/pages/play/portal-campaigns.png) no-repeat 0 0
        display: inline-block
        flex-shrink: 0
        position: relative
        cursor: pointer
        // http://easings.net/#easeOutBack plus tweaked a bit: http://cubic-bezier.com/#.11,.67,.08,1.42
        @include transition(0.25s cubic-bezier(0.11, 0.67, 0.8, 1.42))

        &:hover
          @include scale($campaignHoverScale)

        &.silhouette
          @include filter(contrast(50%) brightness(65%))
          pointer-events: none

        &.locked
          @include filter(contrast(80%) brightness(80%))
          cursor: default

        &.forest
          background-position: (-1 * $campaignPortalWidth) 0
        &.desert
          background-position: (-2 * $campaignPortalWidth) 0
        &.mountain
          background-position: (-3 * $campaignPortalWidth) 0
        &.glacier
          background-position: (-4 * $campaignPortalWidth) 0
        &.volcano
          background-position: (-5 * $campaignPortalWidth) 0
        &.junior
          background-position: (-6 * $campaignPortalWidth) 0

        .campaign-label
          position: absolute
          top: 45%
          width: 100%
          text-align: center

          .campaign-name, .levels-completed, .campaign-locked
            margin: 0
            color: white
            text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0

          .campaign-locked
            margin: 32px 0

          .campaign-description
            margin: 0px 40px
            background: transparent url(/images/level/popover_border_background.png) no-repeat
            background-size: 100% 100%
            padding: 12px
            color: black

          .campaign-age
            font-size: 22px

          .campaign-age-description
            font-style: italic

          .levels-completed
            font-size: 22px

          .play-button
            margin: 15px 0
            min-width: 100px

      .beta-container
        width: $campaignPortalWidth
        height: $campaignPortalHeight
        display: inline-block
        flex-shrink: 0
        position: relative

        .beta-campaign
          width: $campaignPortalWidth
          height: math.div($campaignPortalHeight, 2)
          display: inline-block
          flex-shrink: 0
          position: relative
          cursor: pointer
          // http://easings.net/#easeOutBack plus tweaked a bit: http://cubic-bezier.com/#.11,.67,.08,1.42
          @include transition(0.25s cubic-bezier(0.11, 0.67, 0.8, 1.42))

          &[data-campaign-slug='campaign-game-dev-3']
            margin-top: 230px

          &:hover
            @include scale($campaignHoverScale)

          &.silhouette
            @include filter(contrast(50%) brightness(65%))
            pointer-events: none

          &.locked
            @include filter(contrast(80%) brightness(80%))
            cursor: default

          .campaign-label
            position: absolute
            top: 40%
            width: 100%
            text-align: center

            .campaign-name, .levels-completed, .campaign-locked
              margin: 0
              color: white
              text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0

            .campaign-locked
              margin: 32px 0

            .campaign-description
              margin: 0px 40px
              background: transparent url(/images/level/popover_border_background.png) no-repeat
              background-size: 100% 100%
              padding: 12px
              color: black
              font-size: 12px

            .levels-completed
              font-size: 22px

            .play-button
              margin: 10px 0
              min-width: 100px
              color: white

          .background-container
            position: absolute
            left: math.div($campaignPortalWidth, 4)
            width: math.div($campaignPortalWidth, 2)
            height: math.div($campaignPortalHeight, 2)
            background: transparent url(/images/pages/play/portal-beta-campaigns.png) no-repeat 0 0
            background-size: $betaImagesWidth

            &.campaign-web-dev-1
              background-position: -151px 0px
            &.campaign-game-dev-1
              background-position: -454px 0px
            &.campaign-web-dev-2
              background-position: -291px 0px
            &.campaign-game-dev-2
              background-position: -628px 0px
            &.campaign-game-dev-3
              background-position: -628px 0px

  .portal-catalyst
    position: relative
    width: 100%
    height: 100%
    background: transparent url(/images/pages/play/portal-background.png)
    overflow-y: auto
    padding: 20px

    .portals
      display: flex
      flex-direction: column
      align-items: center
      width: 100%

      .catalyst-first-row
        display: flex
        justify-content: center
        gap: 30px
        width: 100%
        max-width: 1200px
        margin-top: 50px
        margin-bottom: -60px
        
        .campaign
          width: 317px
          height: 634px
          background: transparent url(/images/pages/play/portal-campaigns.png) no-repeat 0 0
          position: relative
          cursor: pointer
          z-index: 0
          @include transition(0.25s cubic-bezier(0.11, 0.67, 0.8, 1.42))

          &:hover
            @include scale($campaignHoverScale)

          &.silhouette
            @include filter(contrast(50%) brightness(65%))
            pointer-events: none
          
          &.junior
            background-position: -1902px 0
          &.dungeon
            background-position: 0 0
          
          .campaign-label
            position: absolute
            top: 45%
            width: 100%
            text-align: center
            
            // Text styling
            .campaign-name, .levels-completed, .campaign-locked
              margin: 0
              color: white
              text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black
            
            .campaign-description-container
              margin: 10px auto
              width: 80%
              
              .campaign-description
                background: transparent url(/images/level/popover_border_background.png) no-repeat
                background-size: 100% 100%
                padding: 12px
                color: black
                font-size: 14px
                line-height: 1.2
                margin: 0
              
            .play-button
              margin: 15px 0
              min-width: 100px
        
      .campaign-rows
        display: flex
        flex-direction: column
        align-items: center
        gap: 20px
        
        .campaign-row
          display: flex
          justify-content: center
          align-items: center
          position: relative
          width: 100%
          height: $campaignPortalHeight
          margin-bottom: 0
          margin-top: -30px
          
          .main-campaign
            width: 317px
            height: $campaignPortalHeight
            background: transparent url(/images/pages/play/portal-campaigns.png) no-repeat
            position: absolute
            cursor: pointer
            z-index: 0
            left: 50%
            transform: translateX(-50%)
            transition: transform 0.25s cubic-bezier(0.11, 0.67, 0.8, 1.42)
            
            &:hover
              transform: translateX(-50%) scale($campaignHoverScale)

            &.silhouette
              @include filter(contrast(50%) brightness(65%))
              pointer-events: none
            
            &.locked
              @include filter(contrast(80%) brightness(80%))
              cursor: default
            
            &.forest
              background-position: (-1 * $campaignPortalWidth) 0
            &.desert
              background-position: (-2 * $campaignPortalWidth) 0
            &.mountain
              background-position: (-3 * $campaignPortalWidth) 0
            &.glacier
              background-position: (-4 * $campaignPortalWidth) 0
            &.volcano
              background-position: (-5 * $campaignPortalWidth) 0
            
            .campaign-label
              position: absolute
              top: 45%
              width: 100%
              text-align: center
              
              // Text styling
              .campaign-name, .levels-completed, .campaign-locked
                margin: 0
                color: white
                text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black
              
              .campaign-description-container
                margin: 10px auto
                width: 80%
                
                .campaign-description
                  background: transparent url(/images/level/popover_border_background.png) no-repeat
                  background-size: 100% 100%
                  padding: 12px
                  color: black
                  font-size: 14px
                  line-height: 1.2
                  margin: 0
                
              .play-button
                margin: 15px 0
                min-width: 100px
            
          .side-campaign-container
            position: absolute
            width: $campaignPortalWidth
            height: math.div($campaignPortalHeight, 2)
            z-index: 0
            top: 317px  // Center vertically (634px / 2)
            transform: translateY(-50%)  // Adjust for half the height of the side campaign
            
            // Left side campaign container (first in the row)
            &.left-campaign
              left: calc(50% - 450px)
            
            // Right side campaign container (last in the row)
            &.right-campaign
              right: calc(50% - 450px)
            
            &.empty
              visibility: hidden
            
            .side-campaign
              width: $campaignPortalWidth
              height: math.div($campaignPortalHeight, 2)
              position: relative
              cursor: pointer
              @include transition(0.25s cubic-bezier(0.11, 0.67, 0.8, 1.42))
          
              &:hover
                @include scale($campaignHoverScale)
                
              &.silhouette
                @include filter(contrast(50%) brightness(65%))
                pointer-events: none
                
              &.locked
                @include filter(contrast(80%) brightness(80%))
                cursor: default
              
              .campaign-label
                position: absolute
                top: 40%
                width: 100%
                text-align: center
                z-index: 1
                
                .campaign-name, .levels-completed, .campaign-locked
                  margin: 0
                  color: white
                  text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0
                
                .campaign-description
                  margin: 0px 40px
                  background: transparent url(/images/level/popover_border_background.png) no-repeat
                  background-size: 100% 100%
                  padding: 12px
                  color: black
                  font-size: 12px
                
                .play-button
                  margin: 10px 0
                  min-width: 100px
                  color: white
              
              .background-container
                position: absolute
                top: 0
                left: math.div($campaignPortalWidth, 4)
                width: math.div($campaignPortalWidth, 2)
                height: 100%
                background: transparent url(/images/pages/play/portal-beta-campaigns.png) no-repeat 0 0
                background-size: $betaImagesWidth
                
                // Game Dev campaigns
                &.campaign-game-dev-1
                  background-position: -454px 0px
                &.campaign-game-dev-2
                  background-position: -628px 0px
                &.campaign-game-dev-3
                  background-position: -628px 0px
                
                // Web Dev campaigns
                &.campaign-web-dev-1
                  background-position: -151px 0px
                &.campaign-web-dev-2
                  background-position: -291px 0px

  #esports-arena
    min-width: 210px

  .small-nav-logo, .picoctf-powered-by
    position: absolute
    top: 1%
    left: 1%
    height: 60px
    z-index: 1

  .picoctf-logo .small-nav-logo
    height: 90px

  .under-logo
    position: absolute
    top: calc(1% + 64px)
    left: 1%
    z-index: 1
    width: 193px

  .picoctf-powered-by
    color: rgb(227, 173, 53)
    top: calc(1% + 90px)
    margin: 10px 10px 10px 15px

    img
      height: 30px

  .code-ninjas-logo
    top: calc(1% + 5px)
    left: calc(1% + 200px)

  .gameplay-container
    position: absolute
    height: 100%
    width: 100%

  #amazon-campaign-logo, #santa-clara-logo, #arapahoe-logo, #garfield-logo, #houston-logo
    cursor: pointer
    width: 200px

  #burnaby-logo
    cursor: pointer
    height: 55px
    border-radius: 10%

  #liverpool-logo, #shreve-logo, #lafourche-logo
    cursor: pointer
    width: 100px
    border-radius: 5%

  #shreve-logo
    width: 150px

  #library-logo
    cursor: pointer
    width: 180px
    border-radius: 5%
    background: white

  #library-logo-container
    display: flex
    justify-content: center

  #shreve-logo-container
    width: 269px

  #santa-clara-logo
    filter: drop-shadow(1px 1px 0px #000) drop-shadow(-1px -1px 0px #000) drop-shadow(1px -1px 0px #000) drop-shadow(-1px 1px 0px #000)

  #garfield-logo
    margin: 20px
    filter: drop-shadow(1px 1px 0px #000) drop-shadow(-1px -1px 0px #000) drop-shadow(1px -1px 0px #000) drop-shadow(-1px 1px 0px #000)

  #arapahoe-logo
    filter: drop-shadow(1px 1px 0px #000) drop-shadow(-1px -1px 0px #000) drop-shadow(1px -1px 0px #000) drop-shadow(-1px 1px 0px #000)
    background-color: #fff

  #houston-logo
    filter: drop-shadow(1px 1px 0px #000) drop-shadow(-1px -1px 0px #000) drop-shadow(1px -1px 0px #000) drop-shadow(-1px 1px 0px #000)
    background-color: #fff

  #burnaby-logo
    filter: drop-shadow(1px 1px 0px #000) drop-shadow(-1px -1px 0px #000) drop-shadow(1px -1px 0px #000) drop-shadow(-1px 1px 0px #000)
    background-color: #fff

  #liverpool-logo, #shreve-logo
    filter: drop-shadow(1px 1px 0px #000) drop-shadow(-1px -1px 0px #000) drop-shadow(1px -1px 0px #000) drop-shadow(-1px 1px 0px #000)
    background-color: #fff

  #game-dev-hoc-license-alert
    position: absolute
    z-index: 1
    bottom: -20px
    left: 0
    right: 0
    text-align: center
    padding: 5px

body.ipad #campaign-view
  // iPad only supports up to Kithgard Gates for now.
  .campaign-switch
    display: none

body[lang='ru'] .portals h2
  font-size: 26px

body:not([lang*="-"]) #campaign-view .user-status .user-status-line .language-dropdown
  width: 148px

body[lang^="en"] #campaign-view .user-status .user-status-line .language-dropdown
  width: 112px

body[lang^="en"] #campaign-view .user-status-catalyst .user-status-line .language-dropdown
  width: 48px

.m7-off
  display: none
.beta-levels
  margin-left: 10px
  font-size: 14px
  vertical-align: middle
  color: gray
  &:hover .m7-off
    display: inline
    &:hover
      cursor: pointer
      color: white

+keyframes(currentPracticeLevel)
  from
    @include box-shadow(0px 0px 8px #333)
    color: white
  50%
    @include box-shadow(0px 0px 35px #87CEFF)
    color: #87CEFF
  to
    @include box-shadow(0px 0px 8px #333)
    color: white

.language-button
  padding: 3px 8px
  @include opacity(0.75)

  &:hover
    @include opacity(1.0)

  .glyphicon
    font-size: 28px

// Hide the actual select element but keep it in the DOM for functionality
.language-dropdown.hidden
  position: absolute
  opacity: 0
  pointer-events: none

.utility-controls-catalyst
  position: absolute
  right: 20px
  bottom: 20px
  z-index: 10
  display: flex
  gap: 10px
  
  .btn
    width: 40px
    height: 40px
    padding: 3px 8px
    @include opacity(0.75)
    @include box-shadow(2px 2px 4px black)
    border: 0
    border-radius: 12px
    
    &:hover
      @include opacity(1.0)
      @include box-shadow(0 0 12px #bbf)
    
    &:active, &.highlighted
      @include box-shadow(0 0 20px white)
    
    .glyphicon
      font-size: 28px
    
    #volume-button
      margin-right: 10px

    &.language-dropdown
      width: 112px


